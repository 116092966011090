<template>
  <div
    :class="{ fullscreen: fullscreen }"
    class="tinymce-container"
    :style="{ width: containerWidth }"
  >
    <textarea :id="tinymceId" class="tinymce-textarea" />
    <!--<div class="editor-custom-btn-container">
      <editorImage
        class="editor-upload-btn"
        @successCBK="imageSuccessCBK"
      />
    </div>-->
  </div>
</template>

<script>
/**
 * docs:
 * https://panjiachen.github.io/vue-element-admin-site/feature/component/rich-editor.html#tinymce
 */
import plugins from "./plugins";
import toolbar from "./toolbar";
import load from "./dynamicLoadScript";
import editorImage from "./components/EditorImage2.vue";
import API from '@/api'

const tinymceFile = `${
  process.browser ? "static" : __static
}/tinymce/tinymce.min.js`;

export default {
  name: "Tinymce",
  components: { editorImage },
  props: {
    id: {
      type: String,
      default: function () {
        return (
          "vue-tinymce-" +
          +new Date() +
          ((Math.random() * 1000).toFixed(0) + "")
        );
      },
    },
    value: {
      type: String,
      default: "",
    },
    toolbar: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    menubar: {
      type: String,
      default: "",
    },
    height: {
      type: [Number, String],
      required: false,
      default: 360,
    },
    width: {
      type: [Number, String],
      required: false,
      default: "auto",
    },
  },
  data() {
    return {
      hasChange: false,
      hasInit: false,
      tinymceId: this.id,
      fullscreen: false,
      languageTypeList: {
        zh: "zh_CN",
      },
    };
  },
  computed: {
    containerWidth() {
      const width = this.width;
      if (/^[\d]+(\.[\d]+)?$/.test(width)) {
        // matches `100`, `'100'`
        return `${width}px`;
      }
      return width;
    },
  },
  watch: {
    value: {
      handler(val) {
        if (!this.hasChange && this.hasInit) {
          this.$nextTick(() =>
            window.tinymce.get(this.tinymceId).setContent(val || "")
          )
        }
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.initTinymce();
    }, 500)
  },
  activated() {
    if (window.tinymce) {
      this.initTinymce();
    }
  },
  deactivated() {
    this.destroyTinymce();
  },
  destroyed() {
    this.destroyTinymce();
  },
  methods: {
    init() {
      // dynamic load tinymce from cdn
      load(tinymceFile, (err) => {
        if (err) {
          this.$message.error(err.message);
          return;
        }
        this.initTinymce();
      });
    },
    initTinymce() {
      const _this = this;
      window.tinymce.init({
        selector: `#${this.tinymceId}`,
        language: this.languageTypeList["zh"],
        min_height: this.height,
        body_class: "panel-body ",
        draggable_modal: true,
        object_resizing: false,
        toolbar: this.toolbar.length > 0 ? this.toolbar : toolbar,
        menubar: this.menubar,
        content_style: "p{font-size: 16px; margin: 0; padding: 0}", // 默认样式
        plugins: plugins,
        // 允许粘贴
        paste_data_images: true,
        convert_urls: false, // 图片不使用相对路径
        images_upload_handler: async (blobInfo, success) => {
          // const img = 'data:image/jpeg;base64,' + blobInfo.base64()
          // success(img)

          const res = await _this.$request({
            url: API.base.uploadFile,
            baseURL: '',
            method: 'post',
            data: {
              file: blobInfo.blob()
            }
          })
          if (res.url) {
            success(res.url)
          } else {
            failure(res.msg)
          }
        },
        style_formats: [
          //新增 首行缩进与行高 配置
          {
            title: '首行缩进',
            block: 'p',
            styles: {
              'text-indent': '2em'
            }
          },
          {
            title: 'Headings',
            items: [
              {
                title: 'Heading 1',
                format: 'h1'
              },
              {
                title: 'Heading 2',
                format: 'h2'
              },
              {
                title: 'Heading 3',
                format: 'h3'
              },
              {
                title: 'Heading 4',
                format: 'h4'
              },
              {
                title: 'Heading 5',
                format: 'h5'
              },
              {
                title: 'Heading 6',
                format: 'h6'
              }
            ]
          },
          {
            title: 'Inline',
            items: [
              {
                title: 'Bold',
                icon: 'bold',
                format: 'bold'
              },
              {
                title: 'Italic',
                icon: 'italic',
                format: 'italic'
              },
              {
                title: 'Underline',
                icon: 'underline',
                format: 'underline'
              },
              {
                title: 'Strikethrough',
                icon: 'strikethrough',
                format: 'strikethrough'
              },
              {
                title: 'Superscript',
                icon: 'superscript',
                format: 'superscript'
              },
              {
                title: 'Subscript',
                icon: 'subscript',
                format: 'subscript'
              },
              {
                title: 'Code',
                icon: 'code',
                format: 'code'
              }
            ]
          },
          {
            title: 'Blocks',
            items: [
              {
                title: 'Paragraph',
                format: 'p'
              },
              {
                title: 'Blockquote',
                format: 'blockquote'
              },
              {
                title: 'Div',
                format: 'div'
              },
              {
                title: 'Pre',
                format: 'pre'
              }
            ]
          },
          {
            title: 'Alignment',
            items: [
              {
                title: 'Left',
                icon: 'alignleft',
                format: 'alignleft'
              },
              {
                title: 'Center',
                icon: 'aligncenter',
                format: 'aligncenter'
              },
              {
                title: 'Right',
                icon: 'alignright',
                format: 'alignright'
              },
              {
                title: 'Justify',
                icon: 'alignjustify',
                format: 'alignjustify'
              }
            ]
          }
        ],
        // paste_auto_cleanup_on_paste: true,
        // paste_remove_styles: true,
        // paste_remove_styles_if_webkit: true,
        // paste_strip_class_attributes: true,
        // paste_as_text: true,

        fontsize_formats: "12px 14px 16px 18px 24px 36px 48px 56px 72px",
        font_formats:
          "微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;",
        end_container_on_empty_block: true,
        powerpaste_word_import: "clean",
        code_dialog_height: 450,
        code_dialog_width: 1000,
        custom_undo_redo_levels: 50,
        advlist_bullet_styles: "square",
        advlist_number_styles: "default",
        imagetools_cors_hosts: ["www.tinymce.com", "codepen.io"],
        default_link_target: "_blank",
        link_title: false,
        branding: false,
        nonbreaking_force_tab: true, // inserting nonbreaking space &nbsp; need Nonbreaking Space Plugin
        init_instance_callback: (editor) => {
          // console.log(_this.value)
          if (_this.value) {
            editor.setContent(_this.value);
          }
          _this.hasInit = true;
          editor.on("NodeChange Change KeyUp SetContent", () => {
            this.hasChange = true;
            this.$emit("input", editor.getContent());
          });
        },
        setup(editor) {
          editor.on("FullscreenStateChanged", (e) => {
            _this.fullscreen = e.state;
          });
        },
      });
    },
    destroyTinymce() {
      const tinymce = window.tinymce.get(this.tinymceId);
      if (this.fullscreen) {
        tinymce.execCommand("mceFullScreen");
      }

      if (tinymce) {
        tinymce.destroy();
      }
    },
    setContent(value) {
      window.tinymce.get(this.tinymceId).setContent(value);
    },
    getContent() {
      window.tinymce.get(this.tinymceId).getContent();
    },
    imageSuccessCBK(arr) {
      const _this = this;
      arr.forEach((v) => {
        window.tinymce
          .get(_this.tinymceId)
          .insertContent(`<img class="wscnph" src="${v}" style="width: 100%;" >`);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.tinymce-container {
  position: relative;
  line-height: normal;
}
.tinymce-container >>> .mce-fullscreen {
  z-index: 10000;
}
.tinymce-textarea {
  visibility: hidden;
  z-index: -1;
}
.editor-custom-btn-container {
  position: absolute;
  right: 6px;
  top: 6px;
  z-index: 1;
}
.fullscreen .editor-custom-btn-container {
  z-index: 10000;
  position: fixed;
}
.editor-upload-btn {
  display: inline-block;
}
</style>
