//布局算法的参数定义
export const layoutParamsDict ={
  'hierarchical':[ //层级布局
    {'name':'layerSpace','value': 300,'type':'number','label':'层间距'},
    {'name':'nodeSpace','value': 200,'type':'number','label':'点间距'},
    {'name':'sortType','value': 'selected','type':'array','label':'排序类型',
      'types':[
        {'label':'指定点','value':'selected'},
        {'label':'度大小','value':'hubsize'},
        {'label':'连线方向','value':'directed'}
      ]
    },
    {'name':'direction','value': 'UD','type':'array','label':'方向',
      'types':[
        {'label':'上下','value':'UD'},
        {'label':'下上','value':'DU'},
        {'label':'左右','value':'LR'},
        {'label':'右左','value':'RL'}
      ]
    }
  ],
  'grid':[ //网格布局
    {'name':'layerSpace','value': 300,'type':'number','label':'层间距'},
    {'name':'nodeSpace','value': 200,'type':'number','label':'点间距'}
  ],
  'concentric':[ //同心圆布局
    {'name':'maxNodeSize','value': 220,'type':'number','label':'节点间距'}
  ],
  'fastForce':[ //网络布局
    {'name':'linkDistance','value': 380,'type':'number','label':'连线长度'},
    {'name':'charge','value': -200,'type':'number','label':'吸引力'},
    {'name':'friction','value': 0.8,'type':'decimal','label':'排斥力'},
    {'name':'linkStrength','value': 0.03,'type':'decimal','label':'连线强度'},
    {'name':'gravity','value': 0.01,'type':'decimal','label':'中心引力'}
  ],
  'simulation':[ //力导向布局
    {'name':'strength','value': -10000,'type':'number','label':'吸引力'},
    {'name':'ajustCluster','value': false,'type':'switch','label':'类型分组',
      'types':[
        {'label':'是','value':true},
        {'label':'否','value':false}
      ]
    }
  ],
  'kawakai':[ //最优路径布局
    {'name':'sizeScale','value': 3,'type':'number','label':'缩放比例'}
  ],
  'circle':[ //环形布局
    {'name':'scale','value': 2.8,'type':'number','label':'缩放比例'},
    {'name':'ordering','value': 'degree','type':'array','label':'排序方式',
      'types':[
        {'label':'度大小排序','value':'degree'},
        {'label':'拓扑排序','value':'topology'}
      ]
    }
  ],
  'arf':[
    {'name':'neighberForce','value': 5.0,'type':'number','label':'邻边引力'},
    {'name':'attraction','value': 0.025,'type':'number','label':'向心力'},
    {'name':'forceScale','value': 10.0,'type':'number','label':'缩放比例'}
  ],
  'tree':[ //树形布局
    {'name':'layerSpace','value': 150,'type':'number','label':'层间距'},
    {'name':'nodeSpace','value': 60,'type':'number','label':'点间距'},
    {'name':'direction','value': 'UD','type':'array','label':'方向',
      'types':[
        {'label':'上下','value':'UD'},
        {'label':'下上','value':'DU'},
        {'label':'左右','value':'LR'},
        {'label':'右左','value':'RL'}
      ]
    }
  ],
  'avoidlap':[ //避免重叠
    {'name':'maxPadding','value': 10,'type':'number','label':'节点间距'}
  ],
  'dagre':[ //流程图布局
    {'name':'nodesep','value': 10,'type':'number','label':'点间距'},
    {'name':'ranksep','value': 200,'type':'number','label':'层间距'},
    {'name':'nodeSize','value': 50,'type':'number','label':'节点大小'},
    {'name':'rankdir','value': 'TB','type':'array','label':'排列方向',
      'types':[
        {'label':'上下','value':'TB'},
        {'label':'下上','value':'BT'},
        {'label':'左右','value':'LR'},
        {'label':'右左','value':'RL'}
      ]
    }
  ]
};

//布局算法列表
export const layoutTypeList = [
  {label:'网络布局',value:'fastForce',icon:'el-icon-orange'},
  {label:'力导布局',value:'simulation',icon:'el-icon-orange'},
  {label:'最优路径布局',value:'kawakai',icon:'el-icon-cpu'},
  {label:'层级布局',value:'hierarchical',icon:'el-icon-share'},
  {label:'中心圆布局',value:'concentric',icon:'el-icon-bangzhu'},
  {label:'环形布局',value:'circle',icon:'el-icon-bangzhu'},
  {label:'球面引力布局',value:'arf',icon:'el-icon-s-grid'},
  {label:'流向布局',value:'dagre',icon:'el-icon-share'},
  {label:'网格布局',value:'grid',icon:'el-icon-s-grid'},
  {label:'去除重叠',value:'avoidlap',icon:'el-icon-copy-document'}
];

//获取默认的布局参数
export const getLayoutDefaultConfig = function(layoutType){
  let layoutConfig = {};
  layoutParamsDict[layoutType].forEach(param=>{
    layoutConfig[param.name] = param.value;
  });
  return layoutConfig;
};

//获取布局参数列表
export const getLayoutParams = function(layoutType){
  return layoutParamsDict[layoutType];
};