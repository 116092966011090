export default {
  login: {
    AccountMenu: '/account/menu', //菜单
    Logout: '/account/logout', // 登出
    Login: '/login', // 登录
    getInfo: '/account/profile', // 获取用户信息
    accountEdit: '/account/update', // 修改用户信息
    changeAccountPsd: '/account/changePwd', // 更改密码
  },
  base: {
    refreshCache: '/account/refresh_cache', //清除缓存
    sysdetail: '/sysdetail', //系统消息
    areas: '/basic/areas_by_type_id', // 管辖区域 根据type和id获取管辖区域
    uploadFile: '/admin/upload/file', // 附件上传
  },
  /* 日志 */
  log: {
    logList: '/newUserLog', //日志列表
    loginStatistics: '/user_log', //登录列表
    recordError: '/record/error', //上传错误
    errorLog: '/errorLog', // 错误列表
  },
  /* 用户组 */
  departmentManagement: {
    groupList: '/group', //用户组管理列表
    groupGrant: '/group/grant', //授权
    groupAdd: '/group/add', //新增
    groupEdit: '/group/update', //编辑
  },
  /* 用户管理 */
  user: {
    userList: '/user', //后台用户列表
    changePwd: '/user/changePwd', //修改密码
    addUser: '/user/add', //添加用户
    editUser: '/user/update', //编辑用户
    detailUser: '/user/detail' //用户详情
  },
  /* 菜单 */
  menu: {
    MenuAllTree: '/menu/allTreeList', //菜单树 所有
    MenuTree: '/menu/tree', //菜单树
    MenuList: '/menu', //菜单列表
    MenuDel: '/menu/delete', //删除菜单
    MenuAdd: '/menu/add', //添加菜单
    MenuEdit: '/menu/update', //编辑菜单
  },
  /* 类型管理 */
  type: {
    typeList: '/category', //列表
    typeDel: '/category/delete', //删除
    typeAdd: '/category/add', //添加
    typeEdit: '/category/update', //编辑
  }
}