/*
* 默认路由，主页、菜单、用户等
* */
const childrenPage = [
  {
    path:'/dashboard',
    name:'dashboard',
    meta:{title:"我的主页"},
    component:() => import('@/views/dashboard/index')
  },
  {
    path:'/demo',
    name:'demo',
    meta:{title:"DEMO", code: 'demo'},
    component:() => import('@/views/demo/demo'),
  },
  {
    path:'/publicComponent',
    name:'publicComponent',
    meta:{title:"组件"},
    component:() => import('@/views/demo/publicComponent'),
  },
  {
    path:'/baiduMap',
    name:'baiduMap',
    meta:{title:"百度地图"},
    component:() => import('@/views/demo/baiduMap')
  },
  {
    path:'/graphvis',
    name:'graphvis',
    meta:{title:"关系图谱"},
    component:() => import('@/views/demo/graphvis')
  },
  {
    path:'/xmind',
    name:'xmind',
    meta:{title:"思维导图"},
    component:() => import('@/views/demo/xmind')
  },
  // 系统设置
  // {
  //   path:'/menuManagement',
  //   name:'menuManagement',
  //   meta:{ title:'菜单管理', code: 'menu' },
  //   component:() => import('@/views/system/menuManagement/index')
  // },
  // {
  //   path: '/areaManagement',
  //   name: 'areaManagement',
  //   component: () => import('@/views/system/areaManagement/index'),
  //   meta: { title: '区域管理' }
  // },
  // {
  //   path: '/userManagement',
  //   name: 'userManagement',
  //   component: () => import('@/views/system/userManagement/index'),
  //   meta: { title: '用户管理', code: 'user' }
  // },
  {
    path: '/departmentManagement',
    name: 'departmentManagement',
    component: () => import('@/views/system/departmentManagement/index'),
    meta: { title: '用户组管理' }
  },
  {
    path: '/TypeManagement',
    name: 'TypeManagement',
    component: () => import('@/views/system/TypeManagement/index'),
    meta: { title: '类型管理' }
  },
  {
    path: '/LogManagement',
    name: 'LogManagement',
    component: () => import('@/views/system/LogManagement/index'),
    meta: { title: '日志管理' }
  },
  {
    path: '/messageManage',
    name: 'messageManage',
    component: () => import('@/views/system/messageManage/index'),
    meta: { title: '消息管理' }
  },
  // {
  //   path: '/errorLog',
  //   name: 'errorLog',
  //   component: () => import('@/views/system/errorLog/index'),
  //   meta: { title: '错误日志' }
  // }




  // {
  //   path: '/logonStatistics',
  //   name: 'logonStatistics',
  //   component: () => import('@/views/system/logonStatistics/index'),
  //   meta: { title: '用户登录统计' }
  // },
  // end
]

export default childrenPage;
