const config = {

}

export default {
  // 是否显示头部
  showHeader: true,
  headerBackgroundColor: '#202D8A', // 背景色
  headerTextColor: '#ffffff', // 文字颜色

  /* 菜单 */
  showMenu: true,
  menuBackgroundColor: '', // 背景色
  menuTextColor: '', // 文字颜色
  menuActiveTextColor: '', // 选中文字颜色

  /* tab */
  tabBackground: 'rgba(204, 209, 248, 1)', // 选中背景色
  tabTextColor: '#202D8A', // 选中文字颜色

  // 是否水印
  enableWatermark: true,

  /* 底部信息 */
  showCopyright: true,
  copyrightTime: '技术支持',
  copyrightCompany: 'xxxxxxx',
  copyrightDomain: '', // 公司网址
  copyrightFiling: '', // 备案信息
  ...config
}
