/*
* 公共JS方法文件
* */

import Vue from 'vue'
import { message } from "ant-design-vue/es";

/* 空数据输出 -- */
function isEmpty (val = '') {
  if (val === null || val === '' || val.length === 0 ) {
    return "--";
  }
  return val;
}

/* 名字脱敏 */
function filterName (name = '') {
  if (name.length === 2) {
    return name.substring(0, 1) + '*' //截取name 字符串截取第一个字符，
  } else if (name.length === 3) {
    return name.substring(0, 1) + "*" + name.substring(2, 3)//截取第一个和第三个字符
  } else if (name.length > 3) {
    return name.substring(0, 1) + "*" + '*' + name.substring(3, name.length)//截取第一个和大于第4个字符
  } else {
    return name
  }
}

/* 电话脱敏 */
function filterPhone (val = '') {
  if (!val) {
    return "--"
  }
  let reg = /^(.{3}).*(.{4})$/
  return val.replace(reg, '$1****$2')
}

/* 身份证号脱敏 */
export function filterIdCard(val) {
  if (!val) {
    return "--"
  }
  return val.replace(/^(.{6})(?:\d+)(.{4})$/, "$1****$2");
}

/*
* 格式化时间
* time 时间戳 （秒） 例：1668062192
* option 例：{y}-{m}-{d} {h}:{i}:{s} || {y}年{m}月星期{a}
* */
function formatTime(time, isRead = false, option = null) {
  time = time ? +time * 1000 : new Date()
  if (isRead) {
    const d = new Date(time)
    const now = Date.now()
    const diff = (now - d) / 1000
    if (diff < 30) {
      return '刚刚'
    } else if (diff < 3600) {
      return Math.ceil(diff / 60) + '分钟前'
    } else if (diff < 3600 * 24) {
      return Math.ceil(diff / 3600) + '小时前'
    } else if (diff < 3600 * 24 * 2) {
      return '1天前'
    }
  }
  if (option || time) {
    const format = option || '{y}-{m}-{d} {h}:{i}:{s}'
    let date
    if (typeof time === 'object') {
      date = time
    } else {
      if (('' + time).length === 10) time = parseInt(time) * 1000
      date = new Date(time)
    }
    const formatObj = {
      y: date.getFullYear(),
      m: date.getMonth() + 1,
      d: date.getDate(),
      h: date.getHours(),
      i: date.getMinutes(),
      s: date.getSeconds(),
      a: date.getDay()
    }
    return format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
      let value = formatObj[key]
      if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value ] }
      if (result.length > 0 && value < 10) {
        value = '0' + value
      }
      return value || 0
    })
  } else {
    return time.getFullYear() + '-' + time.getMonth() + '-' + time.getDate() + ' ' + time.getHours() + ':' + time.getMinutes() + ':' + time.getSeconds()
  }
}

/*
* 下载图片
* url: 下载路径
* downloadName： 输出文件名
* */
function downloadImage(url, downloadName = '下载文件') {
  if (!url) {
    message.error("暂无下载文件");
    return;
  }
  this.getUrlBase64(url).then((base64) => {
    let a = document.createElement("a");
    a.style.display = "none";
    a.download = downloadName;
    a.href = base64;
    document.body.appendChild(a);
    a.click();
  });
}

/*
* 图片转Base64
* */
function getUrlBase64(url) {
  //图片转base64
  return new Promise((resolve) => {
    let canvas = document.createElement("canvas");
    let ctx = canvas.getContext("2d");
    let img = new Image();
    img.crossOrigin = "Anonymous"; //允许跨域
    img.src = url;
    img.onload = function () {
      // console.log(img.width, img.height)
      canvas.height = img.height;
      canvas.width = img.width;
      ctx.drawImage(img, 0, 0, img.width, img.height);
      let dataURL = canvas.toDataURL("image/png");
      canvas = null;
      resolve(dataURL);
    };
  });
}

/*
* 普通数字转会计金额格式
* num: 要转换的数字
* fixed：保留的小数位数
* */
function getAccountingAmount(num, fixed = 0) {
  // 判断传进来的数字是否为非空数字
  if (!isNaN(parseFloat(num))) {
    return Number(Number(num).toFixed(fixed)).toLocaleString('zh', { minimumFractionDigits: fixed });
  } else {
    return ''
  }
}

/*
* 复制
* text: 要复制的文本
* */
function copyText(text) {
  const txa = document.createElement("textarea")
  txa.value = text
  document.body.appendChild(txa)
  txa.select()
  document.execCommand("Copy")
  document.body.removeChild(txa)
  message.success('已复制')
}

/*
* 打印
* */
function webPrint(target) {
  const printContentHtml = target.innerHTML;
  const iframe = document.createElement("iframe");
  iframe.setAttribute(
    "style",
    "display: none;",
  );
  document.body.appendChild(iframe);
  iframe.contentDocument.write(printContentHtml);
  setTimeout(() => {
    iframe.contentDocument.close();
    iframe.contentWindow.print();
    document.body.removeChild(iframe);
  },100);
}

const filters = {
  isEmpty,
  filterName,
  filterPhone,
  filterIdCard,
  formatTime,
  getAccountingAmount,
  copyText
}
/*
* 全局过滤器
* */
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

export default {
  ...filters,
  downloadImage,
  getUrlBase64,
  webPrint
}
