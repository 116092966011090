import 'es6-promise/auto';
import axios from 'axios';
import { message } from "ant-design-vue/es";
import { get } from './cookies';
import router from '../router';
import store from '../store';
import { debounce } from 'ant-design-vue/es/vc-table/src/utils.js'


const m = debounce(showMessage, 1000, true)
function showMessage(res) {
  if(res.code === 401){
    store.dispatch('Logout').then(() => {
      message.warning('登录超时，请重新登录');
      router.push('/login')
    })
  }else{
    message.warning(res.msg);
    return Promise.reject('error')
  }
}

// 创建axios实例
const service = axios.create({
  baseURL: '/admin', // api 的 base_url
  timeout: 10000 // 请求超时时间
})
// request拦截器
service.interceptors.request.use(
  config => {
    config.headers = {
      'Content-Type':'application/json, text/plain, */*'
    }
    if (config.method.toUpperCase() === 'GET') {
      config.params = { api_token: get(), ...config.data, ...config.params }
    } else if (config.method.toUpperCase() === 'POST') {
      if(config.data && config.data.file){
        // 上传
        config.headers = {
          'Content-Type':'multipart/form-data'
        }
        let newForm = new FormData();
        newForm.append('api_token',get());
        for(let key in config.data){
          newForm.append(key,config.data[key]);
        }
        config.data = newForm;
      }else{
        config.data = { api_token: get(), ...config.data }
      }
    }

    return config
  },
  error => {
    // Do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)
// response 拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code !== 200) {
      m(res)
    } else{
      return res.data
    }
  },
  error => {
    console.log('requestError', error)
    if (error.response && error.response.status === 401) {
      store.dispatch('Logout').then(() => {
        message.warning('登录超时，请重新登录');
        router.push('/login')
      })
    } else if (error.config.url === '/record/error') {

    } else if (error.response && error.response.status === 404) {
      message.warning('请求地址不存在')
      return Promise.reject(error)
    } else {
      message.warning('请求超时，请重试')
      return Promise.reject(error)
      // message.warning(error.response.data.message?error.response.data.message:'请求错误!');
    }
  }
)

export default service
