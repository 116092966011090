
String.format = function() { //字符串中赋值变量
  if (arguments.length === 0)
    return null;
  var str = arguments[0];
  for ( var i = 1; i < arguments.length; i++) {
    var re = new RegExp('\\{' + (i - 1) + '\\}', 'gm');
    str = str.replace(re, arguments[i]);
  }
  return str;
}
const style = document.createElement('style');
let css =
  '.mauto{ margin: 0 auto}' +
  '.wper{width: 100%}' +
  '.hper{height: 100%}' +
  '.w-screen:{width: 100vw}' +
  '.h-screen{height: 100vh}' +
  '.font-bold{font-weight: bold}';
const base = [
  /* 文字 */
  { label: 'ls{0}', value: 'letter-spacing: {0}px', max: 10},
  { label: 'lh{0}', value: 'line-height: {0}px', max: 50},
  { label: 'font-w{0}00', value: 'font-weight: {0}00', max: 9},
  { label: 'txt-{0}', value: 'font-size: {0}px'},
  /* 圆角 */
  { label: 'br{0}', value: 'border-radius: {0}px', max: 50},
  /* 延迟执行 */
  { label: 'transition{0}', value: 'transition: 0.{0}s', max: 9},
  /* 内间距 */
  { label: 'p{0}', value: 'padding: {0}px'},
  { label: 'pt{0}', value: 'padding-top: {0}px'},
  { label: 'pb{0}', value: 'padding-bottom: {0}px'},
  { label: 'pl{0}', value: 'padding-left: {0}px'},
  { label: 'pr{0}', value: 'padding-right: {0}px'},
  { label: 'px{0}', value: 'padding-left: {0}px;padding-right: {0}px'},
  { label: 'py{0}', value: 'padding-top: {0}px;padding-bottom: {0}px'},
  /* 外间距 */
  { label: 'm{0}', value: 'margin: {0}px'},
  { label: 'mt{0}', value: 'margin-top: {0}px'},
  { label: 'mb{0}', value: 'margin-bottom: {0}px'},
  { label: 'ml{0}', value: 'margin-left: {0}px'},
  { label: 'mr{0}', value: 'margin-right: {0}px'},
  { label: 'mx{0}', value: 'margin-left: {0}px;margin-right: {0}px'},
  { label: 'my{0}', value: 'margin-top: {0}px;margin-bottom: {0}px'},
  { label: 'zi{0}', value: 'z-index: {0}'},
  /* 宽高 */
  { label: 'w{0}', value: 'width: {0}px'},
  { label: 'w{0}00', value: 'width: {0}00px', max: 9 },
  { label: 'wper{0}', value: 'width: {0}%'},
  { label: 'h{0}', value: 'height: {0}px'},
  { label: 'h{0}00', value: 'height: {0}00px', max: 9 },
  { label: 'hper{0}', value: 'height: {0}%'},
  /* 位置 */
  { label: 'top{0}', value: 'top: {0}px'},
  { label: 'bottom{0}', value: 'bottom: {0}px'},
  { label: 'left{0}', value: 'left: {0}px'},
  { label: 'right{0}', value: 'right: {0}px'},
  { label: 'topper{0}', value: 'top: {0}%'},
  { label: 'bottomper{0}', value: 'bottom: {0}%'},
  { label: 'leftper{0}', value: 'left: {0}%'},
  { label: 'rightper{0}', value: 'right: {0}%'},
]
const color = [
  /* 颜色 */
  { label: 'text-white', value: 'color: var(--white)' },
  { label: 'text-black', value: 'color: var(--black)' }, // 标题
  { label: 'text-slate-1', value: 'color: var(--slate-1)' }, // 一级文字
  { label: 'text-slate-2', value: 'color: var(--slate-2)' }, // 二级文字
  { label: 'text-slate-3', value: 'color: var(--slate-3)' }, // 占位符
  { label: 'text-slate-4', value: 'color: var(--slate-4)' }, // 图标色
  { label: 'text-success', value: 'color:var(--success)' },
  { label: 'text-success2', value: 'color:var(--success-bg)' },
  { label: 'text-warning', value: 'color: var(--warning)' },
  { label: 'text-warning2', value: 'color: var(--warning-bg)' },
  { label: 'text-error', value: 'color: var(--error)' },
  { label: 'text-error2', value: 'color: var(--error-bg)' },
  { label: 'text-primary', value: 'color: var(--primary)' },
  { label: 'text-primary2', value: 'color: var(--primary-bg)' },
]
const background = [
  /* 背景 */
  { label: 'bg-white', value: 'background: var(--white)' },
  { label: 'bg-black', value: 'background: var(--black)' },
  { label: 'bg-slate-1', value: 'background: var(--slate-1)' },
  { label: 'bg-slate-2', value: 'background: var(--slate-2)' },
  { label: 'bg-slate-3', value: 'background: var(--slate-3)' },
  { label: 'bg-slate-4', value: 'background: var(--slate-4)' },
  { label: 'bg-success', value: 'background:var(--success)' },
  { label: 'bg-success2', value: 'background:var(--success-bg)' },
  { label: 'bg-warning', value: 'background: var(--warning)' },
  { label: 'bg-warning2', value: 'background: var(--warning-bg)' },
  { label: 'bg-error', value: 'background: var(--error)' },
  { label: 'bg-error2', value: 'background: var(--error-bg)' },
  { label: 'bg-primary', value: 'background: var(--primary)' },
  { label: 'bg-primary2', value: 'background: var(--primary-bg)' },
]
base.forEach(item => {
  for (let i = 0; i <= (item.max || 100); i++) {
    css += `.${String.format(item.label, i)}{${String.format(item.value, i)}}`
  }
})
color.forEach(item => {
  css += `.${item.label}{${item.value}}`
  css += `.hover-${item.label}:hover{${item.value}}`
})
background.forEach(item => {
  css += `.${item.label}{${item.value}}`
  css += `.hover-${item.label}:hover{${item.value}}`
})
css = css.split(' ').join('')
// 将样式文本添加到 style 元素
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);