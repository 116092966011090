<template>
  <div class="upload-container">
    <!--<a-button
      size="small"
      type=""
      @click="dialogVisible=true"
      icon="cloud-upload"
    > </a-button>
    <a-modal :visible="dialogVisible" :closable="false">

      <template #footer>
        <a-button @click="dialogVisible=false">取消</a-button>
        <a-button type="primary" @click="handleSubmit">确定</a-button>
      </template>
    </a-modal>-->
    <a-upload
      ref="upload"
      :fileList="fileList"
      :showUploadList="true"
      :beforeUpload="beforeUpload"
      :customRequest="customRequest"
      class="upload-list-inline"
      list-type="picture"
    >
      <a-button size="small" icon="cloud-upload" title="上传图片"></a-button>
    </a-upload>
  </div>
</template>

<script>
import API from '@/api'
import axios from 'axios';
export default {
  name: "EditorImage2",
  props: {
    color: {
      type: String,
      default: "#1890ff",
    },
    action:{
      type: String,
      default() {
        return API.base.uploadFile
      }
    },
    limit:{
      type: Number,
      default() {
        return 5;
      }
    }
  },

  data() {
    return {
      dialogVisible: false,
      listObj: {},
      fileList:[],
      picPostData: {},
      limitMessage:true
    }
  },
  computed: {
    token() {
      return this.$cookies.get()
    }
  },
  methods: {
    async customRequest(data) {
      try {
        const formData = new FormData()
        formData.append('file', data.file)
        formData.append('token', this.$cookies.get())
        // const res = await this.$request({
        //   url: this.action,
        //   method: 'post',
        //   data: formData
        // })
        axios({
          url: '/admin' + this.action,
          method: 'POST',
          data: formData,
        }).then(res => {
          if (res.data.code === 200) {
            const arr = [res.data.data.url]
            this.$emit("successCBK", arr);
          } else {
            this.$message.error(res.data.msg)
          }
          // console.log(res)
        })
      } finally {

      }
    },
    checkAllSuccess() {
      return Object.keys(this.listObj).every(
        (item) => this.listObj[item].hasSuccess
      )
    },
    handleSubmit() {
      const arr = Object.keys(this.listObj).map((v) => this.listObj[v]);
      //const arr=[1,2,3,4]
      console.log(arr);
      if (!this.checkAllSuccess()) {
        this.$message(
          "请等待所有图片上传完成，如果存在网络问题请刷新当前页重新上传"
        );
        return;
      }
      this.$emit("successCBK", arr);
      this.listObj = {};
      this.fileList = [];
      this.dialogVisible = false;
    },
    beforeUpload(file, UpFileList){
      //上传文件之前的钩子
      if (this.fileList.length+UpFileList.length>this.limit){
        if(this.limitMessage){
          this.$message.warning('最多允许传5张图片')
          return false
          setTimeout(()=>{
            this.limitMessage=true
          },100)
        }
        return false
      }
      return true
    },
    handleChange(info) {
      console.log(info)
      //上传文件改变时的状态
      if (info.file.status === 'uploading') {
      }
      if (info.file.status === 'done') {
        this.handleSuccess(info.file,info.file.response)
      }
      if (info.file.status === 'error') {
        this.handleError(info.file.name)
      }
      if (info.file.status === 'remove') {
        this.handleRemove(info.file)
      }
    },
    handleRemove(file) {
      const uid = file.uid;
      const objKeyArr = Object.keys(this.listObj);
      for (let i = 0, len = objKeyArr.length; i < len; i++) {
        if (this.listObj[objKeyArr[i]].uid === uid) {
          delete this.listObj[objKeyArr[i]];
          return;
        }
      }
    },
    handleError(err) {
      console.log(err);
      this.$alert(err, "发生错误", {
        confirmButtonText: "确定",
        callback: (action) => {
        },
      });
    },
    handleSuccess(file, response) {
      console.log("file", file);
      console.log("handleSuccess",response);
      const uid = file.uid;
      const objKeyArr = Object.keys(this.listObj);
      for (let i = 0, len = objKeyArr.length; i < len; i++) {
        if (this.listObj[objKeyArr[i]].uid === uid) {
          this.listObj[objKeyArr[i]].url = 'http://www.dyboke.cn/storage/image/20221209/c858afcbcef871dc18d66b662f9d09f5.png';
          this.listObj[objKeyArr[i]].hasSuccess = true;
          return;
        }
      }
    },
  }

}
</script>

<style lang="scss" scoped>
.upload-list-inline :deep(.ant-upload-list-item) {
  float: left;
  width: 200px;
  margin-right: 8px;
}
.upload-list-inline :deep(.ant-upload-animate-enter) {
  animation-name: uploadAnimateInlineIn;
}
.upload-list-inline :deep(.ant-upload-animate-leave) {
  animation-name: uploadAnimateInlineOut;
}
</style>
