<template>
  <!--style="border-bottom: 1px solid #F2F3F5 "-->
  <div class="pt16 ml16 mr16" >
    <a-row>
      <a-form-model :model="form">
        <a-col :span="24">
          <a-row :gutter="15" type="flex">
            <slot name="beforeItem" />
            <a-col v-for="(item, name, index) in renderList" v-if="index < showIndex && item.renderName !== 'slot'" :key="index" :span="item.span || 4">
              <a-form-model-item :label="item.label" :class="isAutoWidth ? 'isAutoWidth' : ''" :label-col="!isAutoWidth ? { span: item.labelCol || 8 } : null" :wrapper-col="!isAutoWidth ? { span: item.wrapperCol || 16 } : null">
                <template v-if="item.renderName === 'aSelect'">
                  <a-select v-model="form[name]" class="wper" :filter-option="filterOption" v-bind="{ placeholder: '请选择', ...item.props }">
                    <a-select-option
                      v-for="(v, k) in item.options"
                      :key="k"
                      :value="item.optionProps ? v[item.optionProps.value] : v.value"
                      v-bind="v.props"
                      v-on="v.events"
                    >
                      {{ item.optionProps ? v[item.optionProps.label] : v.label }}
                    </a-select-option>
                  </a-select>
                </template>
                <template v-else-if="item.renderName === 'aCascader'">
                  <a-cascader
                    class="wper"
                    v-model="form[name]"
                    v-bind="{ placeholder: `请选择`, ...item.props }"
                    :options="item.options"
                    v-on="item.events"
                  ></a-cascader>
                </template>
                <template v-else-if="item.renderName === 'aDatePicker'">
                  <a-date-picker
                    class="wper"
                    v-model="form[name]"
                    v-bind="{ format: 'YYYY-MM-DD', valueFormat: 'YYYY-MM-DD', ...item.props }"
                    v-on="item.events"
                  />
                </template>
                <template v-else-if="item.renderName === 'aRangePicker'">
                  <a-range-picker
                    class="wper"
                    v-model="form[name]"
                    v-bind="{ format: 'YYYY-MM-DD', valueFormat: 'YYYY-MM-DD', ...item.props }"
                    v-on="item.events"
                  />
                </template>
                <template v-else-if="item.renderName === 'aYear'">
                  <a-date-picker
                    class="wper"
                    v-model="form[name]"
                    :allowClear="false"
                    v-bind="{ mode: 'year', format: 'YYYY', placeholder: `请选择${item.label || '年份'}`, ...item.props }"
                    :open="yearPickShow"
                    @panelChange="val => changeYear(item, name, val)"
                    @openChange="val => yearPickShow = val"
                  />
                </template>
                <template v-else>
                  <a-input
                    class="wper"
                    v-model="form[name]"
                    v-bind="{ placeholder: '请输入', ...item.props }"
                    v-on="item.events"
                  />
                </template>
              </a-form-model-item>
            </a-col>
            <slot name="afterItem" v-if="showIndex === 99 || Object.keys(renderList).length <= setShowIndex" />
            <a-col :span="buttonSpan" flex="1">
              <a-form-item :style="showIndex === 99 ? 'text-align: right' : showIndex === setShowIndex && Object.keys(renderList).length > setShowIndex ? 'text-align: right' : 'text-align: left'">
                <div style="white-space: nowrap">
                  <a-button :loading="loading" type="primary" @click="search">{{ searchName }}</a-button>
                  <a-button :disabled="loading" class="ml12 mr12" @click="reset">重置</a-button>
                  <slot name="button" />
                  <a class="inline-block" v-if="showIndex === 99" @click="showIndex = setShowIndex"><a-icon type="up" class="mr3" />收起</a>
                  <a class="inline-block" v-if="showIndex === setShowIndex && Object.keys(renderList).length > setShowIndex" @click="showIndex = 99"><a-icon type="down" class="mr3" />展开</a>
                </div>
              </a-form-item>
            </a-col>
          </a-row>
        </a-col>
      </a-form-model>
    </a-row>
  </div>
</template>
<script>
  export default {
    props: {
      value: {
        type: Object,
        default: () => {}
      },
      /* 是否自撑宽度 */
      isAutoWidth: {
        type: Boolean,
        default: true
      },
      renderData: {
        type: Object,
        default: () => {}
      },
      loading: {
        type: Boolean,
        default: false
      },
      // 搜索重置按钮栏宽度
      buttonSpan: {
        type: Number,
        default: 5
      },
      searchName: {
        type: String,
        default: '搜索'
      },
      // 展示筛选条件的数量
      setShowIndex: {
        type: Number,
        default: 5
      }
    },
    data() {
      return {
        showIndex: 0, // 展示的搜索item数
        // loading: false,
        renderList: {},
        form: {},

        yearPickShow: false
      }
    },
    mounted() {
      this.showIndex = this.setShowIndex
    },
    watch: {
      value: {
        handler(val) {
          this.form = val
        },
        immediate: true
      },
      form: {
        handler(val) {
          this.$emit('input', val)
        },
        deep: true,
        immediate: true
      },
      renderData: {
        handler(newVal) {
          if (JSON.stringify(newVal) !== JSON.stringify(this.renderList)) {
            const data = JSON.parse(JSON.stringify(newVal))
            for (const i in data) {
              data[i].props = { allowClear: true, ...data[i].props }
            }
            this.renderList = data
          }
        },
        deep: true,
        immediate: true
      }
    },
    methods: {
      filterOption(input, option) {
        return (
            option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
        )
      },
      search() {
        this.$emit('handleSearch')
      },
      reset() {
        this.$emit('handleReset')
      },
      changeYear(item, name, value) {
        this.form[name] = value.format('YYYY');
        this.yearPickShow = false;
      },
    }
  }
</script>
<style lang="scss" scoped>
  .isAutoWidth {
    display: flex;
    ::v-deep .ant-form-item-control-wrapper {
      flex: 1
    }
  }
  ::v-deep .ant-form-item-label > label {
    /*text-align: left;*/
    color: #4E5969;
  }
  ::v-deep .ant-form-item {
    margin-bottom: 0 !important;
  }
  ::v-deep .ant-input {
    /*border: none;*/
    /*background: #F7F8FA;*/
  }
  ::v-deep .ant-input:focus {
    box-shadow: 0 0 0 1px rgb(0, 151, 254);
  }
  ::v-deep .ant-select-selection {
    /*border: none;*/
    /*background: #F7F8FA;*/
  }
  ::v-deep .ant-select-open .ant-select-selection {
    box-shadow: 0 0 0 1px rgb(0, 151, 254);
  }
</style>
