// Here is a list of the toolbar
// Detail list see https://www.tinymce.com/docs/advanced/editor-control-identifiers/#toolbarcontrols

// eslint-disable-next-line no-multi-str
/*
*  code undo redo restoredraft | cut copy paste pastetext | anchor
*  | fullscreen | bdmap indent2em lineheight formatpainter axupimgs
*  | bullist numlist | blockquote subscript superscript removeformat
* print
* styleselect formatselect
* table media charmap emoticons insertdatetime fullscreen
* */
const toolbar = ['styleselect code removeformat forecolor backcolor bold italic underline strikethrough link  | alignleft aligncenter alignright alignjustify outdent indent | \
 fontselect lineheight fontsizeselect | \
 image hr pagebreak | preview']

export default toolbar
