import Vue from 'vue'
import VueRouter from 'vue-router'
import childrenPage from './childrenPage'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('@/layout'),
    // component: () => import('@/components/layout/layout'),
    redirect:'/demo',
    meta:{ title:"主页" },
    children:childrenPage
  },
  { path: '/login',name:'login', component: () => import('@/views/login/index.vue') },
  // { path: '*',name:'404', component: () => import('@/views/404.vue') }
]

let router = new VueRouter({
  routes
})
//
// router.beforeEach(async (to, from, next) => {
//   const arr = []
//   function getRoute(data) {
//     data.forEach(item => {
//       if (item.children && item.children.length) {
//         getRoute(item.children)
//       } else {
//         arr.push(
//           {
//             path: item.url,
//             name: item.url,
//             meta:{ title: item.name , code: item.code, icon: item.icon },
//             component:() => import(`@/views/${item.frontend_path}`)
//           }
//         )
//       }
//     })
//   }
//
//   const white_list = ['login']
//   if(white_list.indexOf(to.name) > -1){
//     next()
//   } else if (!store.state.user.isGenerate) {
//     const time = setInterval(() => {
//       if (store.state.user.menus && store.state.user.menus.length) {
//         getRoute(store.state.user.menus)
//       }
//       if (arr && arr.length) {
//         clearInterval(time)
//         router.addRoute({
//           path: '/',
//           component: () => import('@/layout'),
//           meta:{ title:"主页" },
//           children: arr
//         })
//         // console.log('arr', JSON.parse(JSON.stringify(arr)))
//         store.commit('SET_IS_GENERATE', true)
//       }
//     }, 1000)
//     next(false)
//   }
//   next()
// })

export default router
