<template>
  <a-form-model ref="form" :model="form" v-bind="{ ...settingOption }">
    <a-row :gutter="20">
      <div v-for="(item, name, index) in renderList" :key="index">
        <template v-if="item.renderType && item.renderType === 'slot'">
          <slot v-if="name && !item.hide" :name="name"></slot>
        </template>
        <template v-else>
          <a-col v-if="!item.hide" :span="item.span || 24">
            <a-form-model-item :label="item.label" :prop="item.valid ? name: ''" v-bind="{ ...item.setting }">
              <template v-if="item.renderName === 'aSelect'">
                <a-select
                  v-model="form[name]"
                  :filter-option="filterOption"
                  v-bind="{ placeholder: `请选择${item.label}`, ...item.props }"
                  v-on="item.events"
                >
                  <a-select-option
                    v-for="(v, k) in item.options"
                    :key="k"
                    :value="item.optionProps ? v[item.optionProps.value] : v.value"
                    v-bind="v.props"
                    v-on="v.events"
                  >
                    {{ item.optionProps ? v[item.optionProps.label] : v.label }}
                  </a-select-option>
                </a-select>
              </template>
              <template v-else-if="item.renderName === 'aCascader'">
                <a-cascader
                  v-model="form[name]"
                  v-bind="{ placeholder: `请选择${item.label}`, ...item.props }"
                  :options="item.options"
                  v-on="item.events"
                ></a-cascader>
              </template>
              <template v-else-if="item.renderName === 'aInputNumber'">
                <a-input-number
                  class="w180"
                  v-model="form[name]"
                  v-bind="{ placeholder: `请输入${item.label}`, ...item.props }"
                  v-on="item.events"
                ></a-input-number>
              </template>
              <template v-else-if="item.renderName === 'aInputPassword'">
                <a-input-password
                  v-model="form[name]"
                  v-bind="{ placeholder: `请输入${item.label}`, ...item.props }"
                />
              </template>
              <template v-else-if="item.renderName === 'aRadioGroup'">
                <a-radio-group
                  v-model="form[name]"
                  :options="item.options"
                  v-bind="item.props"
                  v-on="item.events"
                />
              </template>
              <template v-else-if="item.renderName === 'aCheckboxGroup'">
                <a-checkbox-group
                  v-model="form[name]"
                  :options="item.options"
                  v-bind="item.props"
                  v-on="item.events"
                />
              </template>
              <template v-else-if="item.renderName === 'aSwitch'">
                <a-switch
                  v-model="form[name]"
                  v-bind="item.props"
                  v-on="item.events"
                ></a-switch>
              </template>
              <template v-else-if="item.renderName === 'aDatePicker'">
                <a-date-picker
                  v-model="form[name]"
                  v-bind="{ format: 'YYYY-MM-DD', valueFormat: 'YYYY-MM-DD', ...item.props }"
                  v-on="item.events"
                />
              </template>
              <template v-else-if="item.renderName === 'aYear'">
                <a-date-picker
                  v-model="form[name]"
                  :allowClear="false"
                  v-bind="{ mode: 'year', format: 'YYYY', placeholder: `请选择${item.label || '年份'}`, ...item.props }"
                  :open="yearPickShow"
                  @panelChange="val => changeYear(item, name, val)"
                  @openChange="val => yearPickShow = val"
                />
              </template>
              <template v-else-if="item.renderName === 'aRangePicker'">
                <a-range-picker
                  v-model="form[name]"
                  v-bind="{ format: 'YYYY-MM-DD', valueFormat: 'YYYY-MM-DD', ...item.props }"
                  v-on="item.events"
                />
              </template>
              <template v-else-if="item.renderName === 'aTreeSelect'">
                <a-tree-select
                  v-model="form[name]"
                  :treeData="item.treeData"
                  v-bind="{
                    // showSearch: true,
                    replaceFields: {
                      children: 'sub',
                      value: 'id',
                      title: 'name',
                    },
                    placeholder: '请选择上级菜单',
                    dropdownStyle: {
                      maxHeight: '200px',
                      overflow: 'auto'
                    },
                    treeDefaultExpandAll: true, // 展开节点
                    showSearch: true, // 搜索
                    treeNodeFilterProp: 'title', // 搜索字段
                    ...item.props
                  }"
                  v-on="item.events"
                />
              </template>
              <template v-else-if="item.renderName === 'aUploadImage'">
                <UploadImage
                  :imgList="form[name]"
                  :del="true"
                  v-bind="{ ...item.props }"
                  @on-success="res => onSuccess(item, name, res)"
                  @on-remove="res => onSuccess(item, name, res)"
                />
              </template>
              <template v-else-if="item.renderName === 'aColor'">
                <el-color-picker
                  v-model="form[name]"
                  v-bind="{ showAlpha: true, ...item.props }"
                  v-on="item.events"
                ></el-color-picker>
              </template>
              <template v-else-if="item.renderName === 'aText'">
                <span>{{ form[name] }}</span>
              </template>
              <template v-else >
                <a-input v-model="form[name]" v-bind="{ placeholder: `请输入${item.label}`, ...item.props }" v-on="item.events"></a-input>
              </template>
            </a-form-model-item>
          </a-col>
        </template>
      </div>
    </a-row>
  </a-form-model>
</template>
<script>
  export default {
    props: {
      value: {
        type: Object,
        default: () => {}
      },
      renderData: {
        type: Object,
        default: () => {}
      },
      /* form配置 */
      setting: {
        type: Object,
        default: () => {}
      },
    },
    data() {
      return {
        renderList: {},
        form: {},
        settingOption: {
          labelCol: { span: 3 }, wrapperCol: { span: 21 }
        },

        yearPickShow: false,
      }
    },
    created() {
      this.settingOption = { ...this.settingOption, ...this.setting }
    },
    watch: {
      value: {
        handler(val) {
          this.form = val
        },
        immediate: true
      },
      form: {
        handler(val) {
          this.$emit('input', val)
        },
        deep: true,
        immediate: true
      },
      renderData: {
        handler(newVal) {
          if (JSON.stringify(newVal) !== JSON.stringify(this.renderList)) {
            const data = newVal
            // const data = this.$common.deepCopy(newVal)
            for (const i in data) {
              data[i].props = { allowClear: true, ...data[i].props }
            }
            this.renderList = data
          }
        },
        deep: true,
        immediate: true
      }
    },
    methods: {
      filterOption(input, option) {
        return (
            option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
        )
      },
      formRef() {
        return this.$refs.form
      },
      onSuccess(item, name, res) {
        if (item.props && item.props.multiple) {
          this.form[name] = res.list
        } else {
          this.form[name] = res.list[0]
        }
      },
      changeYear(item, name, value) {
        this.form[name] = value.format('YYYY');
        this.yearPickShow = false;
      },
    }
  }
</script>
<style lang="scss" scoped>
  ::v-deep .ant-form-item-label > label {
    /*text-align: left;*/
    color: #4E5969;
  }
  ::v-deep .ant-form-item-label {
    line-height: 30px;
  }
  ::v-deep .ant-form-item-control {
    line-height: 30px;
  }
  ::v-deep .ant-form-item {
    margin-bottom: 19px !important;
  }
  // 错误提示
  ::v-deep .ant-form-item-with-help {
    margin-bottom: 0 !important;
  }
  ::v-deep .ant-form-explain {
    padding: 2px 12px;
    font-size: 10px;
  }
  // Input
  ::v-deep .ant-input {
    /*border: none;*/
    /*background: #F7F8FA !important;*/
  }
  ::v-deep .ant-input:focus {
    box-shadow: 0 0 0 1px rgb(0, 151, 254);
  }
  ::v-deep .has-error .ant-input-affix-wrapper .ant-input:focus {
    box-shadow: 0 0 0 1px rgb(245, 50, 92)
  }
  // 计数器
  ::v-deep .ant-input-number {
    /*border: none;*/
    /*background: #F7F8FA;*/
  }
  ::v-deep .ant-input-number-focused {
    box-shadow: 0 0 0 1px rgb(0, 151, 254);
  }
  // 级联
  ::v-deep .ant-cascader-picker {
    /*background: #F7F8FA;*/
  }
  ::v-deep .ant-cascader-picker:focus .ant-cascader-input {
    box-shadow: 0 0 0 1px rgb(0, 151, 254);
  }
  // 选择
  ::v-deep .ant-select-selection {
    /*border: none;*/
    /*background: #F7F8FA;*/
  }
  ::v-deep .ant-select-open .ant-select-selection {
    box-shadow: 0 0 0 1px rgb(0, 151, 254);
  }
</style>
