import API from '@/api'
import request from '@/utils/request'
import cookies from '@/utils/cookies'

const user = {
  state:{
    userName:'',
    userInfo:{},
    isGenerate: false,
    menus:[],  //顶部菜单
    leftMenus:[
      // { id: 1, name: '我的主页', url: 'dashboard'},
      { id: 2, name: 'DEMO', url: 'demo'},
      { id: 3, name: '百度地图', url: 'baiduMap'},
      { id: 4, name: '公共组件', url: 'publicComponent'},
      { id: 5, name: '关系图谱', url: 'graphvis'},
      // { id: 6, name: '思维导图', url: 'xmind'},
    ]  //左侧菜单
  },
  getters:{
    GetLeftMenus(state) {
      return state.leftMenus
    },
    GetUserName(state) {
      return state.userName;
    },
    GetTopLevelMenus (state) {
      return state.menus
    },
    GetUserInfo(state) {
      return state.userInfo;
    }
  },
  mutations: {
    SET_USERNAME(state, userName) {
      state.userName = userName;
    },
    SET_TOP_LEVEL_MENUS(state,menus) {
      state.menus = menus
    },
    SET_LEFT_MENUS(state,menus) {
      state.leftMenus = menus;
    },
    SET_USER_INFO(state,userInfo) {
      state.userInfo = userInfo;
    },
    SET_IS_GENERATE(state,isGenerate) {
      state.isGenerate = isGenerate;
    }
  },
  actions: {
    Logout({ commit }) {
      return new Promise(resolve => {
        cookies.remove();
        commit('SET_USERNAME','');
        commit('SET_USER_INFO',{});
        resolve();
      })
    },
    GetMenus({ commit }) {
      return new Promise((resolve => {
        request({
          url: API.login.AccountMenu
        }).then(res => {
          commit('SET_TOP_LEVEL_MENUS', res)
          commit('SET_LEFT_MENUS',res[0].children)
          resolve()
        })
      }))
    },
    // 获取用户信息
    GetInfo({ commit }) {
      return new Promise((resolve) => {
        request({
          url: API.login.getInfo,
        }).then(res => {
          commit('SET_USERNAME', res.realname);
          commit('SET_USER_INFO',res);
          resolve()
        })
      })
    },
  }
}

export default user;
