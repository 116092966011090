<template>
  <a-config-provider :locale="zhCN">
    <div id="app">
      <router-view/>
    </div>
  </a-config-provider>
</template>
<script>
  // ant 设置中文
  import zhCN from "ant-design-vue/lib/locale-provider/zh_CN"
  export default {
    data(){
      return {
        zhCN
      }
    },
    mounted() {
      setInterval(() => {
        if (this.$cookies.get()) {
          this.$store.dispatch('GetInfo')
        }
      }, 60000 * 10)
    }
  }
</script>
