<template>
  <div class="wper hper pr">
    <div :id="id" ref="graph" style="width: 100%; height: 100%" />
    <a-button-group class="pa left10 top10">
      <a-button icon="rollback" title="撤销" @click="back()" />
      <a-button icon="zoom-in" title="放大" @click="toolBarEvent('zoomOut')" />
      <a-button icon="border-outer" title="原始大小" @click="toolBarEvent('zoom1')" />
      <a-button icon="zoom-out" title="缩小" @click="toolBarEvent('zoomIn')" />
      <a-button icon="download" title="下载" @click="toolBarEvent('download')" />
      <a-popover trigger="click" placement="bottom">
        <div slot="content" class="w300">
          <div class="flex">
            <a-select class="flex1" v-model="currentLayout.layoutType">
              <a-select-option v-for="(item, index) in layoutTypeLists" :key="index" :value="item.value">
                {{ item.label }} - {{ item.value }}
              </a-select-option>
            </a-select>
            <a-button type="primary" class="ml8" @click="runExcuteLocalLayout">开始布局</a-button>
          </div>
          <div class="mt8 bg-primary2 p10">
            <a-form-model :labelCol="{span: 8}" :wrapperCol="{span: 16}">
              <a-form-model-item class="mb0" v-for="(item, index) in layoutParamsDicts[currentLayout.layoutType]" :key="index" :label="item.label">
                <a-input-number class="wper" v-if="item.type === 'number'" v-model="item.value"/>
                <a-input-number class="wper" v-if="item.type === 'decimal'" v-model="item.value" :step="0.1" :min="0" :max="1" />
                <a-switch v-if="item.type === 'switch'" v-model="item.value" />
                <a-select class="wper" v-if="item.type === 'array'" v-model="item.value">
                  <a-select-option v-for="(v, k) in item.types" :key="k" :value="v.value">
                    {{ v.label }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-form-model>
          </div>
        </div>
        <a-button icon="appstore" title="布局" />
      </a-popover>
      <a-button icon="setting" title="配置JSON" @click="show = true" />
    </a-button-group>
    <a-drawer
      v-if="show"
      :visible="show"
      width="400px"
      title="配置"
      @close="show = false">
      <div>
        <div>布局</div>
        <pre>{{ configJson }}</pre>
        <div>节点连线</div>
        <pre>{{ graphVis.serialized() }}</pre>
      </div>
    </a-drawer>
  </div>
</template>
<script>
  import GraphVis from './graphvis.min'
  import {
    layoutTypeList,
    layoutParamsDict,
  } from "./layoutParamsDict.js";
  // import nodeTemplate from "./nodeTemplate";
  export default {
    name: 'graphvis',
    props: {
      id: {
        type: String,
        default: 'graph'
      },
      config: {
        type: Object,
        default: () => {
          return {
            node: { //节点的默认配置
              label: { //标签配置
                // show: true, //是否显示
                color: '50,50,50', //字体颜色
                font: 'normal 14px Arial', //字体大小及类型
                textPosition: 'Bottom_Center', //文字位置 Top_Center,Bottom_Center,Middle_Right
                textOffsetX: 0, //文字横向偏移量(默认不配置)
                textOffsetY: 10, //文字纵向偏移量(默认不配置) 10
                background: null //文字背景色(默认不配置)
              },
              shape: 'circle', //节点形状 circle,star,polygon,square  rect
              // width: 72,
              // height: 80,
              // padding: 10,
              color: null, //节点颜色 255,255,255
              borderColor:'40, 112, 237',//边框颜色
              borderWidth: 0, //边框宽度 1
              // tagColor:'230,30,30',//标记的颜色
              size: 40, //节点半径大小
              borderRadius: 4,
              selected: { //选中时的样式设置
                borderColor: '30,100,250', //选中时边框颜色
                borderAlpha: 1, //选中时的边框透明度
                borderWidth: 2, //选中是的外部边框宽度
                showShadow: true, //是否展示阴影
                shadowColor: '30,100,250', //选中是的阴影颜色
                shadowBlur: 10 //阴影的范围大小
              }
            },
            link: { //连线的默认配置
              label: { //连线标签
                show: true, //是否显示
                color: '20,20,20', //字体颜色
                font: 'normal 13px Arial', //字体大小及类型
                background: null //文字背景色(有背景时上线居中)
              },
              lineType: 'straight', //连线类型,straight,curver,vlink,hlink,vbezier,hbezier
              color: '210,210,210', //连线颜色
              // lineWidth: 2, //连线宽度
              // showArrow: true, //显示连线箭头
              // arrowType:'triangle',//箭头样式arrow,triangle
              selected: { //选中时的样式设置
                color: '50,50,230' //选中时的颜色
              }
            },
            highLightNeiber: false, //相邻节点高度标志
            wheelZoom: 0.9, //滚轮缩放开关，不使用时不设置
            selectBox:{ //框选区域的颜色配置
              color:'10,10,225', //框的颜色
              alpha: 0.1 //框的透明度
            }
          }
        }
      }
    },
    data() {
      return {
        show: false,
        graphVis: null,
        historyData: [],
        currentLayout: {
          layoutType: 'fastForce'
        },
        json: {a: 1},
        layoutTypeLists: layoutTypeList,
        layoutParamsDicts: layoutParamsDict,
      }
    },
    computed: {
      configJson() {
        const layoutConfig = {}
        this.layoutParamsDicts[this.currentLayout.layoutType].forEach(item => {
          layoutConfig[item.name] = item.value
        })
        return {
          layoutType: this.currentLayout.layoutType,
          layoutConfig
        }
      }
    },
    mounted() {
      // this.createGraph()
    },
    methods: {
      // 初始化
      createGraph() {
        this.graphVis = new GraphVis({
          container: document.getElementById(this.id), //画布层
          licenseKey: "zhejiangbaianxinxi", //授权license  zhejiangbaianxinxi
          config: this.config, //全局配置
        })
        // 注册点击事件
        this.registEventListener()
        // node右键事件
        this.registRightMenu()
        // 顶点图片
        this.reConfigNodeCluster()
        // 自定义顶点
        // this.graphVis.registNodeTemplates(nodeTemplate);
      },
      reConfigNodeCluster() {
        this.graphVis.reConfigNodeCluster({
          /* 默认图标 */
          '银行卡': { image: require('./gIcon/bank.png')},
          '虚拟币': { image: require('./gIcon/Blockchain.png')},
          'blued': { image: require('./gIcon/blued.png')},
          '滴滴打车': { image: require('./gIcon/didi.png')},
          '抖音': { image: require('./gIcon/douyin.png')},
          '钉钉': { image: require('./gIcon/dingding.png')},
          'facebook': { image: require('./gIcon/facebook.png')},
          'instagram': { image: require('./gIcon/instagram.png')},
          '快手': { image: require('./gIcon/kuaishou.png')},
          '连我': { image: require('./gIcon/line.png')},
          '沃斯爱普': { image: require('./gIcon/whatsapp.png')},
          '邮箱': { image: require('./gIcon/mail.png')},
          '陌陌': { image: require('./gIcon/momo.png')},
          '手机号': { image: require('./gIcon/phone.png')},
          'QQ': { image: require('./gIcon/QQ.png')},
          'QQ群': { image: require('./gIcon/qqqun.png')},
          'signal': { image: require('./gIcon/signal.png')},
          'SKYPE': { image: require('./gIcon/skeype.png')},
          'telegram': { image: require('./gIcon/telegram.png')}, // TG
          'Twitter': { image: require('./gIcon/Twitter.png')}, // 推特
          '微信群': { image: require('./gIcon/weixinqun.png')},
          'YY语音': { image: require('./gIcon/YY语音.png')},
          '支付宝2': { image: require('./gIcon/zhifubao.png')},
          '蝙蝠': { image: require('./gIcon/蝙蝠.png')},
          '联信': { image: require('./gIcon/联信.png')},
          '头像': { image: require('./gIcon/身份证号码.png')},
          '微信聊天': { image: require('./gIcon/微信账号.png')},
          '虚拟币交易': { image: require('./gIcon/虚拟币交易hash.png')},
          '淘宝': { image: require('./gIcon/淘宝账号.png')},
          '资金渠道': { image: require('./gIcon/资金渠道.png')},
          '其他': { image: require('./gIcon/其他.png')},
          'user': { image: require('./gIcon/player.png')},
          'group': { image: require('./gIcon/team.png')},
        })
      },
      // 点击事件
      registEventListener() {
        const that = this
        // 单击了节点
        this.graphVis.registEventListener('node','click',function(event,node){
          that.$emit('onClickNode',{ event, node })
          // that.config.node.label.show = false
          // console.log(that.graphVis.nodes, that.config)
        })
        // 双击了节点
        this.graphVis.registEventListener('node','dblClick',function(event,node){
          that.$emit('onDBClickNode',{ event, node })
        })
        // 单击了线
        this.graphVis.registEventListener('link','click',function(event,link){
          that.$emit('onClickLink',{ event, link })
        })
        // 点击了画板空白处
        this.graphVis.registEventListener('scene','whiteSpaceClick',function(event,a){
          // console.log('框选的节点:',event,a);
          that.$emit('onWhiteSpaceClick')
        });
      },
      // 注册节点右键菜单
      registRightMenu() {
        const that = this
        this.graphVis.registRightMenu('node',{
          show : function(e,graphvis,node){
            that.$emit('onRightClickNode', { e, graphvis, node })
          },
          hide : function(){}
        });
      },
      // 加点
      addGraph(demoData) {
        this.graphVis.addGraph(demoData);
        this.serialized()
      },
      // 动态添加数据
      activeAddData(node, demoData, layoutType, layoutConfig = {linkDistance: 300, charge: -200, friction: 0}) {
        this.graphVis.activeAddData(
          node,
          demoData,
          layoutType,
          layoutConfig,
          function(newNodes,newLinks){
            //console.log('扩展数据结束',newNodes);
          }
        )
        this.serialized()
      },
      // 布局
      excuteLocalLayout(layoutType = 'fastForce', layoutConfig = {linkDistance: 300, charge: -200, friction: 0}) {
        // const that = this
        this.graphVis.excuteLocalLayout(layoutType, layoutConfig, function () {
          // that.graphVis.zoomFit(); //布局结束缩放居中
        });
      },
      // 清空所有数据
      clearAll() {
        this.graphVis.clearAll()
      },
      // 获取所有节点
      getNodes() {
        return this.graphVis.getNodes()
      },
      // 删除指定节点
      deleteNode(node) {
        this.graphVis.deleteNode(node)
        this.serialized()
      },
      // 批量删除指定节点
      deleteNodes(node) {
        this.graphVis.deleteNodes(node)
        this.serialized()
      },
      // 工具栏
      toolBarEvent(type) {
        switch (type) {
          case 'download':
            this.graphVis.saveImage({
              type: "png",
              fileName: "知识图谱-" + new Date().getTime(),
              background: "#fff",
              textWatermark: {
                content: " ",
                angle: -30,
                alpha: 0.1,
                fontStyle: "normal",
                fontSize: 40,
                fontFamliy: "Arial",
                fontColor: "#444",
              },
            })
            break
          default:
            this.graphVis.setZoom(type)
            break
        }
      },

      runExcuteLocalLayout() {
        const layoutConfig = {}
        this.layoutParamsDicts[this.currentLayout.layoutType].forEach(item => {
          layoutConfig[item.name] = item.value
        })
        this.excuteLocalLayout(this.currentLayout.layoutType, layoutConfig)
      },

      getSerialized() {
        return this.graphVis.serialized()
      },
      // 添加操作历史
      serialized() {
        setTimeout(() => {
          const layout = this.graphVis.serialized()
          this.historyData.push(layout)
        }, 1000)
      },
      // 撤销
      back() {
        if (this.historyData.length) {
          this.historyData.splice(this.historyData.length - 1, 1)
          this.clearAll()
          this.graphVis.addGraph(this.historyData[this.historyData.length - 1]);
          // this.excuteLocalLayout()
        }
      }
    }
  }
</script>