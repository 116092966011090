<template>
  <div v-resize="getAutoHeight" class="p16 pb0" style="flex: 1; overflow-y: auto">
    <a-table
      id="tableData"
      :columns="columnsList"
      :dataSource="dataSource"
      :pagination="pagination"
      :loading="loading"
      v-bind="{ ...settingOption }"
      @change="onChange"
      @expand="onExpand"
    >
      <template :slot="v.dataIndex" v-for="(v, k) in columns" slot-scope="text, record, index" >
        <template v-if="v.renderType && v.renderType === 'slot'">
          <slot :name="v.dataIndex" :text="text" :row="record" :index="index" />
        </template>
        <template v-else>
          <template v-if="v.renderType === 'index'">{{ index + 1 + (pagination ? (pagination.current - 1) * pagination.pageSize : 0) }}</template>
          <template v-else-if="v.renderType === 'image'">
            <div v-if="typeof(text) === 'object'">
              <el-image
                v-for="(item, index) in text"
                :key="index"
                :src="item"
                style="width: 50px; height: 50px; margin-right: 10px"
                :preview-src-list="[item]"
              >
                <div slot="error" class="image-slot">
                  <el-image src="@/assets/img/error_img.png" />
                </div>
              </el-image>
            </div>
            <div v-else>
              <el-image
                :src="text"
                style="width: 50px; height: 50px; margin-right: 10px"
                :preview-src-list="[text]"
              >
                <div slot="error" class="image-slot">
                  <img src="@/assets/img/error_img.png" class="wper" />
                </div>
              </el-image>
            </div>
          </template>
          <template v-else-if="v.renderType === 'html'">
            <span
              @click="() => {
                v[v.activeName || 'default'] && v[v.activeName || 'default'](record, index)
              }"
              v-html="v.render(record)"
            />
          </template>
          <template v-else-if="v.renderType === 'readTime'">
            <span>{{ text | formatTime(true) }}</span>
          </template>
          <template v-else-if="v.renderType === 'jsx'">
            <span
              @click="() => {
                v[v.activeName || 'default'] && v[v.activeName || 'default'](record, index)
              }"
            >
              <extend
                :render="v.render"
                :params="record"
              />
            </span>
          </template>
          <template v-else-if="v.renderType === 'handle'">
            <a-space :size="10">
              <span v-permission="v2.permission || 'default'" v-if="!(v2.hide && v2.hide(record, index))" v-for="(v2, k2) in v.childrens" :key="k2">
                <a-button
                  class="p0"
                  :style="v2.style"
                  :size="v2.size ? v2.size : 'small'"
                  :type="v2.type ? v2.type : 'link'"
                  @click.native="() => { v2[v2.activeName || 'default'](record, index) }"
                >{{ v2.label }}</a-button>
              </span>
              <a-dropdown v-if="v.dropdown">
                <a class="ant-dropdown-link">
                  <span class="">更多</span> <a-icon type="down" />
                </a>
                <a-menu slot="overlay">
                  <a-menu-item v-permission="v2.permission || 'default'" v-show="!(v2.hide && v2.hide(record, index))" v-for="(v2, k2) in v.dropdown" :key="k2">
                    <a :style="v2.style" @click="() => { v2[v2.activeName || 'default'](record, index) }" >{{ v2.label }}</a>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </a-space>
          </template>
          <template v-else>
            <a-tooltip v-if="v.ellipsis" placement="topLeft" :title="text">
              {{ text | isEmpty }}
            </a-tooltip>
            <span v-else>{{ text | isEmpty }}</span>
          </template>
        </template>
      </template>
    </a-table>
  </div>
</template>
<script>
  import extend from './extend'
  export default {
    components: { extend },
    props: {
      // 表头
      columns: {
        type: [Array, Object],
        default: () => {}
      },
      // 数据
      dataSource: {
        type: [Array, Object],
        default: () => []
      },
      //分页
      page: {
        type: [Object, Boolean],
        default: () =>{}
      },
      //表格配置
      setting: {
        type: Object,
        default: () => {}
      },
      loading: {
        type: Boolean,
        default: false
      },
      /** 是否自动高度(自适应) */
      isGetAutoHeight: {
        type: Boolean,
        default: true
      },
      otherHeight: {
        type: Number,
        default: 125
      }
    },
    data() {
      return {
        //默认配置
        settingOption: {
          scroll: { y: 600 },
          size: 'small'
        },
        columnsList: [],
        pagination: {
          current: 1,
          pageSize: 20,
          size: 'default',
          pageSizeOptions: ['10', '20', '50', '100', '300', '500'],
          total: 0,
          showSizeChanger: true,
          showTotal: (total => `共 ${total} 条记录`),
          /* 分页样式：剧中 */
          /*style: {
            position: "relative",
            right: "50%",
            transform: "translate(50%)",
          },*/
        }
      }
    },
    directives: {
      // 自定义指令名称
      resize: {
        // 绑定时调用
        bind(el, binding){
          let height = ""
          function isResize(){
            // 可根据需求，调整内部代码，利用binding.value返回即可
            const style = document.defaultView.getComputedStyle(el)
            if(height !== style.height){
              // 此处关键代码，通过此处代码将数据进行返回，从而做到自适应
              binding.value({height: style.height})
              // console.log(style.height)
            }
            height = style.height
          }
          // 设置调用函数的延时，间隔过短会消耗过多资源
          el.__vueSetInterval__ = setInterval(isResize, 100)
        },
        unbind(el){
          clearInterval(el.__vueSetInterval__)
        }
      }
    },
    watch: {
      loading: {
        handler() {
          if (this.page) {
            this.pagination = {...this.pagination, ...this.page}
          } else {
            this.pagination = this.page
          }
        }
      },
      columns: {
        handler() {
          this.columnsList = this.columns.map(item => {
            return {
              ...item,
              scopedSlots: { customRender: item.dataIndex }
            }
          })
        }
      },
      'page.total'() {
        if (this.page) {
          this.pagination = {...this.pagination, ...this.page}
        } else {
          this.pagination = this.page
        }
      }
    },
    created() {
      this.columnsList = this.columns.map(item => {
        return {
          ...item,
          scopedSlots: { customRender: item.dataIndex }
        }
      })
      this.settingOption = { ...this.settingOption, ...this.setting }
    },
    mounted() {
      if (this.page) {
        this.pagination = {...this.pagination, ...this.page}
      } else {
        this.pagination = this.page
      }
    },
    methods: {
      getAutoHeight(data) {
        if (this.isGetAutoHeight) {
          // console.log('d', data)
          const el = document.querySelector('#tableData')
          if (el) {
            let height = data.height
            height = height.substr('px', height.length - 2)
            this.settingOption.scroll.y = height - this.otherHeight < 300 ? 300 : height - this.otherHeight
          }
        }
      },
      onChange(page, filters, sorter, { currentDataSource }) {
        this.pagination = page
        this.$emit('onChange', page, filters, sorter, { currentDataSource })
      },
      onExpand(expanded, record) {
        this.$emit('onExpand', expanded, record)
      }
    }
  }
</script>
<style lang="scss" scoped>
  // 表头背景
  ::v-deep .ant-table-thead {
    background: #F2F8FF;
  }
  // 去边框
  ::v-deep .ant-table-small {
    border: none;
  }
  ::v-deep .ant-table-thead > tr > th {
    /*font-weight: 500;*/
    color: #262626;
  }
  // 选择框
  ::v-deep .ant-table-selection-col {
    width: 48px !important;
  }
  // 表头滚动条
  ::v-deep .ant-table-fixed-header .ant-table-scroll .ant-table-header {
    background: #F2F8FF;
    box-sizing: border-box !important;
  }
</style>
