import router from './router';
import store from './store';
import NProgress from 'nprogress'; // progress bar
import 'nprogress/nprogress.css'; // progress bar style
import { get } from './utils/cookies'

NProgress.configure({ showSpinner: false })// NProgress Configuration

/* 去除登录验证 白名单 */
const white_list = ['login'];
router.beforeEach(async (to, from, next) => {
  NProgress.start();
  if(white_list.indexOf(to.name) !== -1){
    next();
  }else{
    if (get()) {
      if (to.path === '/login') {
        next({ path: '/' })
        NProgress.done();
      }else{
        next();
        // if (!store.getters.userName) {
        //   await store.dispatch('GetInfo')
        //   await store.dispatch('GetMenus')
        //   next()
        // } else {
        //   // 正确进入
        //   next();
        // }
      }
    }else{
      next({ path: '/login'})
      NProgress.done()
    }
  }
})
router.afterEach(() => {
  NProgress.done() // 结束Progress
})
