import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 登录验证过滤
import '@/permission'
// 错误日志
// import './utils/errorLog'
// 自定义指令（验证按钮权限）
// import './utils/directive'
// 右键菜单
import Contextmenu from "vue-contextmenujs"
Vue.use(Contextmenu)

// 引入ant
import Antd from 'ant-design-vue'
import './styles/ant.less'
Vue.use(Antd)

// 按需引入 Element
import { Image, Upload, Menu, MenuItem, Submenu, MenuItemGroup, ColorPicker, Loading } from 'element-ui'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
Vue.use(Loading)
// 设置语言
locale.use(lang)
// 引入组件
Vue.component(Image.name, Image)
Vue.component(Upload.name, Upload)
Vue.component(Menu.name, Menu)
Vue.component(MenuItem.name, MenuItem)
Vue.component(Submenu.name, Submenu)
Vue.component(MenuItemGroup.name, MenuItemGroup)
Vue.component(ColorPicker.name, ColorPicker)
import 'element-ui/lib/theme-chalk/index.css';

/* 公共css */
import 'animate.css' // 动画
import './styles/index.scss'
import './styles/createCss.js'

/* 全局js */
import common from './utils/common'
Vue.prototype.$common = common
import cookies from './utils/cookies'
Vue.prototype.$cookies = cookies
import request from './utils/request'
Vue.prototype.$request = request
import API from './api/index'
Vue.prototype.$API = API
Vue.prototype.$c = (print) => {
  if (typeof(print) === 'object') {
    console.log('%c打印', 'color:#fff;background: #0f0', JSON.parse(JSON.stringify(print)))
  } else {
    console.log(print)
  }
}
Vue.prototype.$processInfo = process.env

/** 全局组件*/
import aSearchForm from '@/components/aSearchForm'
import aTables from '@/components/aTable'
import aFormData from '@/components/aFormData'
import Tinymce from '@/components/Tinymce'
import UploadImage from '@/components/uploadImage'
import graphvis from '@/components/graphvis'
Vue.component('aSearchForm', aSearchForm)
Vue.component('aTables', aTables)
Vue.component('aFormData', aFormData)
Vue.component('Tinymce', Tinymce)
Vue.component('UploadImage', UploadImage)
Vue.component('graphvis', graphvis)

Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
