<template>
  <div class="upload-box">
    <a-spin :spinning="loading">
      <div
        v-for="(item, index) of list"
        :key="index"
        class="card"
        title="查看大图"
      >
        <div class="img-box">
          <el-image :src="item.imgUrl" :preview-src-list="srcList" />
        </div>

        <a-button
          v-if="del && !disabled"
          class="del-btn"
          type="danger"
          size="small"
          icon="delete"
          shape="circle"
          @click="onClickDel(item, index)"
        ></a-button>
      </div>
      <el-upload
        :action="action"
        :data="{ api_token, ...data}"
        :show-file-list="false"
        :limit="limit"
        :before-upload="default_beforeUpload"
        :on-exceed="onExceed"
        :on-success="onSuccess"
        :on-error="onError"
      >
        <div v-if="!disabled" class="upload-btn">
          <a-icon type="plus" />
        </div>
      </el-upload>
    </a-spin>
  </div>
</template>

<script>
  import API from '@/api'
  export default {
    props: {
      // 接口
      action: {
        type: String,
        default() {
          return API.base.uploadFile
        }
      },
      // 传参
      data: {
        type: Object,
        default() {}
      },
      // 图片列表
      imgList: {
        type: [String, Array],
        default: () => []
      },
      // 是否多张
      multiple: {
        type: Boolean,
        default: false
      },
      // 最大上传数
      limit: {
        type: Number,
        default: 5
      },
      disabled: {
        type: Boolean,
        default: false
      },
      del: {
        type: Boolean,
        default: false
      },
      beforeUpload: {
        type: Function,
        default: null
      },
      options: {
        type: Object,
        default() {
          return {}
        }
      }
    },
    data() {
      return {
        loading: false,
        list: []
      }
    },
    computed: {
      importOptions() {
        return Object.assign(
            {
              maxSize: 5,
              fileType: 'image/jpeg,image/png,image/jpg'
            },
            this.options
        )
      },
      srcList() {
        return this.list.map((item) => item.imgUrl)
      },
      api_token() {
        return this.$cookies.get() //this.$cookies.get()
      }
    },
    watch: {
      imgList: {
        handler(val) {
          if (!val || !val[0] || val.length === 0) {
            this.list = []
            return
          }
          if (this.multiple) {
            this.list = val.map((item) => {
              return {
                imgUrl: item
              }
            })
          } else {
            this.list = [{ imgUrl: typeof (val) === 'string' ? val : val[0] }]
          }
        },
        immediate: true
      },
      beforeUpload: {
        handler(fun) {
          if (fun) {
            this.default_beforeUpload = this.beforeUpload
          }
        },
        immediate: true
      }
    },
    methods: {
      default_beforeUpload(file) {
        this.loading = true
        const isExt = this.importOptions.fileType.indexOf(file.type) >= 0
        const isLt = file.size / 1024 / 1024 < this.importOptions.maxSize
        if (!isExt) {
          this.loading = false
          this.$message.error(
              `上传图片只能是 ${this.importOptions.fileType} 格式!`
          )
        }
        if (!isLt) {
          this.loading = false
          this.$message.error(
              `上传图片大小不能超过 ${this.importOptions.maxSize}MB!`
          )
        }
        return isExt && isLt
      },
      onExceed(res) {
        this.$message.error(`最大上传图片不超过 ${this.limit}张!`)
      },
      onSuccess(res) {
        // console.log(res)
        this.loading = false
        if (res.code === 200) {
          if (this.multiple) {
            this.list.push({ imgUrl: res.data.url })
          } else {
            this.list = [{ imgUrl: res.data.url }]
          }
          // console.log(this.list)
          this.$emit('on-success', {
            body: res.data.url,
            list: this.list.map((item) => item.imgUrl)
          })
        } else {
          this.$message.error(res.msg)
        }
      },
      onError() {
        this.loading = false
      },
      onClickDel(item, index) {
        this.list.splice(index, 1)
        item.visible = false
        this.$emit('on-remove', {
          body: {
            delUrl: item.imgUrl
          },
          list: this.list.map((item) => item.imgUrl)
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep .el-upload__input {
    display: none !important;
  }
  .upload-box {
    padding-top: 14px;
    .card {
      float: left;
      width: 100px;
      height: 100px;
      margin: 0 20px 10px 0;
      position: relative;
      .del-btn {
        position: absolute;
        right: -10px;
        top: -10px;
      }
      .img-box {
        width: 100%;
        height: 100%;
        border: 1px solid #c0ccda;
        border-radius: 6px;
        overflow: hidden;
        .el-image {
          width: 100%;
        }
      }
    }
    .upload-btn {
      float: left;
      background-color: #fbfdff;
      border: 1px dashed #c0ccda;
      border-radius: 6px;
      box-sizing: border-box;
      margin: 0 20px 10px 0;
      width: 100px;
      height: 100px;
      cursor: pointer;
      line-height: 146px;
      display: flex;
      align-items: center;
      justify-content: center;
      .el-icon-plus {
        font-size: 28px;
        color: #8c939d;
      }
    }
  }
</style>
