import Cookies from 'js-cookie'

const name = 'token'
export function set(token, key = name){
  return Cookies.set(key,token)
}

export function get(key = name){
  return Cookies.get(key)
}

export function remove(key = name) {
  Cookies.remove(key)
}

export default {
  set,
  get,
  remove
}


