import setting from "@/setting";
export default {
  state: {
    ...setting
  },
  mutations: {
    SET_SETTING(state, data) {
      state = { ...state, ...data }
    },
    // 更新主题配置
    updateThemeSetting(state, data) {
      Object.assign(state, data)
    }
  }
}